/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import BibleLayout from '@layouts/BibleLayout'
import FacebookBiblePageContainer from '@containers/FacebookBiblePage/FacebookBiblePageContainer'
import avaliableLangs from '@localization/avaliableLangs'

const IndexPage = ({ location }) => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Die Facebook Ads Bible - von Patrick Wind</title>
        <link
          rel='canonical'
          href='https://reports.onlinepunks.com/de/facebook/'
        />
        <meta
          property='og:url'
          content='https://reports.onlinepunks.com/de/facebook/'
        />
        <meta
          property='og:title'
          content='The Facebook Bible - By Patrick Wind'
        />
        <meta
          property='og:image'
          content='https://reports.onlinepunks.com/fbible/footer@2x.png'
        />
        <meta
          property='og:description'
          content=' Kostenloses Facebook Ads E-Book von Patrick Wind - präsentiert von weCreate.'
        />
        <meta property='og:type' content='website' />
        <meta name='twitter:card' content='summary' />
        <script
          id='pixel-script-poptin'
          src='https://cdn.popt.in/pixel.js?id=df783521a0109'
          async='true'
        />
      </Helmet>
      <BibleLayout location={location} pageLanguage={avaliableLangs.de}>
        <FacebookBiblePageContainer />
      </BibleLayout>
    </>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default IndexPage
